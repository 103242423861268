import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import TextField from '@material-ui/core/TextField';
import { BaseUrl } from '../../../Constants';



const useStyles = makeStyles((theme) => ({
    thead: {
        backgroundColor: '#145F97',
        color: 'white',
    },
    theadcell: {
        color: 'white',
        padding: '10px',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
      width:550,
      marginLeft: theme.spacing(2.5),
    },
  }));







export default function History(props) {
    const classes = useStyles();
    const [rows,setRows] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [startDate, setStartDate] = React.useState("2021-04-25T");
    const [endDate, setEndDate] = React.useState("2021-04-30T");
    const [total, setTotal] = React.useState(0);

    function TablePaginationActions(props) {
      const classes = useStyles1();
      const theme = useTheme();
      const { page, onChangePage } = props;
    
    
      const handleBackButtonClick = (event) => {
        onChangePage(event, page===1?1:page - 1);
      };
    
      const handleNextButtonClick = (event) => {
        console.log(page,total)
        onChangePage(event, page==total?page:page + 1);
      };
    
    
      return (
        <div className={classes.root}>
          <IconButton onClick={handleBackButtonClick}  aria-label="previous page">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            aria-label="next page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
        </div>
      );
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    React.useEffect(()=>{
      axios
        .get(BaseUrl+"user/gethistorical", {
            params:{imei:props.imei,page}
            
        })
        .then(function (res) {
          if (res.data.status) {
            console.log("total",res.data.total_pages)
              if(res.data.total_pages)
              setTotal(res.data.total_pages);
              setRows(res.data.data)
          };
        })
        .catch(function (error) {
          
        });
    },[page])

    React.useEffect(()=>{
        axios
        .get(BaseUrl+"user/gethistorical", {
            params:{imei:props.imei,page}
            
        })
        .then(function (res) {
          if (res.data.status) {
            console.log("total",res.data.total_pages)
            if(res.data.total_pages)
            setTotal(res.data.total_pages);
              setRows(res.data.data)
          };
        })
        .catch(function (error) {
          
        });
    },[])

    return (
      <React.Fragment>
        
        <Button style={{ margin: 20, position: 'absolute', top: '10%', left: '5%', zIndex: 1 }} onClick={props.closeGraph} variant="contained" color='primary'>{'<<Back'}</Button>
        <div style={{ display: 'flex',justifyContent:"flex-end" }}>
        <TextField
            id="date"
            label="Start Date"
            type="date"
            onChange={(e)=>setStartDate(e.target.value)}
            defaultValue="2021-04-25"
            style={{width:200,margin:10,marginTop:130}}
          />
          <TextField
            id="date"
            label="End Date"
            type="date"
            onChange={(e)=>setEndDate(e.target.value)}
            defaultValue="2021-05-30"
            style={{width:200,margin:10,marginTop:130}}
          />
          <Button variant="outlined"  href={BaseUrl + `user/getbydate?imei=${props.imei}&start_date=${startDate}&end_date=${endDate}`} color='primary' style={{ marginTop: 150,marginLeft:20 }}>Download</Button>
          <Button variant="outlined"  href={BaseUrl + `user/getdownload?imei=${props.imei}`} color='primary' style={{ marginTop: 150,marginLeft:20 }}>last Month Data</Button>
          </div>
        <Table size="medium" style={{ marginTop: 20 }} size="small">
                <TableHead className={classes.thead}>
                    <TableRow>
                        <TableCell className={classes.theadcell}>Serial No.</TableCell>
                        <TableCell className={classes.theadcell}>Flowrate</TableCell>
                        <TableCell className={classes.theadcell}>Type</TableCell>
                        <TableCell className={classes.theadcell}>Total</TableCell>
                        <TableCell className={classes.theadcell}>Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{(page-1)*15+index+1}</TableCell>
                            <TableCell>{row.flow_rate}</TableCell>
                            <TableCell>{row.strg==0?"Live":"Offline"}</TableCell>
                            <TableCell>{row.total_flow}</TableCell>
                            <TableCell >{row.date_time.split("T")[1].replace("Z","").replace(".000"," ")+
                            row.date_time.split("T")[0].split("-")[2]+"/"+row.date_time.split("T")[0].split("-")[1]+"/"+row.date_time.split("T")[0].split("-")[0]}</TableCell>
                        </TableRow>
                    ))
                  }
                </TableBody>
                <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={15}
                        colSpan={3}
                        count={rows.length}
                        rowsPerPage={15}
                        labelDisplayedRows={()=>null}
                        page={page}
                        onChangePage={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                </TableFooter>
            </Table>
        </React.Fragment >
    );
}