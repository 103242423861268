import React from "react";
import { TextField, Typography, Button, IconButton, InputAdornment } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { BaseUrl } from "../../Constants";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  addmargin: {
    marginTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#37698E",
    "&:hover": {
      backgroundColor: "#37698E",
    },
  },
}))(Button);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddUser = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [project, setProject] = React.useState("");
  const [customer, setCustomer] = React.useState("");
  const [eye, setEye] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setError("");
    if (!name || !email || !password || !phone) {
      setError("Please fill all fields");
    } else if (!email.includes("@") || !email.includes(".")) {
      setError("Enter valid email");
    } else {
      let user_id = localStorage.getItem("user_id");
      if (user_id) {
        axios
          .post(BaseUrl+"admin/create", {
            user_id,
            email,
            password,
            client_name: name,
            phone,
            project_name: project,
            customer_name: customer,
          })
          .then(function (res) {
            if (res.data.status) {
              setName("");
              setPassword("");
              setPhone("");
              setProject("");
              setCustomer("");
              setEmail("")
              setOpen(true);
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  return (
    <div>
      <div className={classes.toolbar} />
      <Typography variant="h4">Add User</Typography>
      <div>
        {loading ? (
          <div style={{ flex: 1, padding: 20 }}>
            <TextField
              className={classes.addmargin}
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.addmargin}
              label="Password"
              id="toggle password visibility"
              value={password}
              type={eye?"text":"password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setEye(!eye)}
                    >
                      {eye ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.addmargin}
              label="Client Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.addmargin}
              label="Phone No."
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.addmargin}
              label="Project Name(Optional)"
              value={project}
              onChange={(e) => {
                setProject(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.addmargin}
              label="Customer Name(Optional)"
              value={customer}
              onChange={(e) => {
                setCustomer(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <Typography color="error">{error}</Typography>
            <ColorButton
              className={classes.addmargin}
              variant="contained"
              onClick={handleClick}
              type="submit"
            >
              Submit
            </ColorButton>
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={() => setOpen(false)}
            >
              <Alert onClose={() => setOpen(false)} severity="success">
                Account Created
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <div>Loading Failed</div>
        )}
      </div>
    </div>
  );
};
export default AddUser;
